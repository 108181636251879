import { UsageMeteringActionTypes } from './actions'
import { DateTime } from 'luxon'
import { DateRange } from '../revenueInsightsV2/reducer'
import { Reducer } from 'redux'

export interface Record {
  name: string
  pricePerBandwidth: string
  quantity: string
  price: string
}
export interface BuyerDetails {
  cloudCustomerId: string
  labraSubscriptionId: string
  buyerCompanyName: string
  buyerLogoUrl: string
}
export interface ProductDetails {
  cloud: string
  cloudProductId: string
  productName: string
}
export interface UsageDimensions {
  labraDimensionId: string
  price: number
  name: string
  dimensionUnit: string
}

export interface UsageData {
  labraMeteringId: string
  meteredQuantity: number
  batchId: string
  cloudMeteringStatus: string
  labraMeteringStatus: string
  meteringSubmissionTimestamp: string
  buyerDetails: BuyerDetails
  productDetails: ProductDetails
  dimension: UsageDimensions
  link: string
}
export interface LinksData {
  self: string
  nextPage: string
  previousPage: string
}
export interface MetaData {
  pageNumber: number
  pageSize: number
  totalCount: number
  sortBy: string
  sortOrder: string
  links?: LinksData
}
export interface UsageFilterState {
  status: string[]
  cloud: string[]
  dateRangeFilter?: any
}
export interface UsageDataState {
  data: UsageData[]
  dateRange: DateRange
  metaData: MetaData
  filters: UsageFilterState
}
export const usageStatusMap: { [key: string]: string } = {
  Success: 'success',
  'In-progress': 'in_progress',
  Failed: 'failed',
}
export const cloudOptions: { [key: string]: string } = {
  AWS: 'AWS',
  GCP: 'GCP',
  Microsoft: 'AZURE',
}
export const initialFilterUsageState: UsageFilterState = {
  status: [],
  cloud: [],
  dateRangeFilter: {
    startDate: DateTime.now().minus({ months: 1 }).toISODate() as string,
    endDate: DateTime.now().toISODate() as string,
  },
}

const initialState: UsageDataState = {
  data: [],
  dateRange: {
    startDate: DateTime.now().minus({ months: 1 }).toISODate() as string,
    endDate: DateTime.now().toISODate() as string,
  },
  metaData: {
    pageNumber: 1,
    pageSize: 15,
    totalCount: 0,
    sortBy: 'created_at',
    sortOrder: 'desc',
  },
  filters: { ...initialFilterUsageState },
}

type actionType =
  | {
      type: UsageMeteringActionTypes.SET_USAGE_METERING_DATA
      payload: UsageDataState
    }
  | {
      type: UsageMeteringActionTypes.CLEAR_USAGE_METERING_DATA
    }
  | {
      type: UsageMeteringActionTypes.SET_USAGE_DATE_RANGE
      payload: DateRange
    }
  | {
      type: UsageMeteringActionTypes.SET_USAGE_FILTERS
      payload: UsageFilterState
    }
  | { type: UsageMeteringActionTypes.CLEAR_USAGE_FILTERS }
  | {
      type: UsageMeteringActionTypes.SET_PAGE_NUMBER
      payload: number
    }

export const reducer: Reducer<UsageDataState, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UsageMeteringActionTypes.SET_USAGE_METERING_DATA: {
      const existingIds = new Set(
        state.data.map(record => record.labraMeteringId)
      )

      const updatedData = action.payload.data.filter(
        record => !existingIds.has(record.labraMeteringId)
      )
      return {
        ...state,
        ...action.payload,
        data: state.data.concat(updatedData),
      }
    }

    case UsageMeteringActionTypes.CLEAR_USAGE_METERING_DATA: {
      return initialState
    }

    case UsageMeteringActionTypes.SET_USAGE_DATE_RANGE: {
      return {
        ...state,
        dateRange: action.payload,
      }
    }
    case UsageMeteringActionTypes.SET_USAGE_FILTERS: {
      return {
        ...state,
        filters: action.payload,
      }
    }
    case UsageMeteringActionTypes.CLEAR_USAGE_FILTERS: {
      return {
        ...state,
        filters: {
          ...initialFilterUsageState,
        },
      }
    }
    case UsageMeteringActionTypes.SET_PAGE_NUMBER: {
      return {
        ...state,
        metaData: {
          ...state.metaData,
          pageNumber: action.payload,
        },
      }
    }

    default: {
      return state
    }
  }
}
