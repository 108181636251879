import { Avatar, Divider, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { isAdminOrOwner } from '../../utils/isAdminRole'
import { useStyles } from './TeamMembers.styles'
import { get } from 'lodash'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { UserState } from '../../../oppsync/modules/userList/reducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { showAddUserForm } from '../../modules/AddUserForm/actions'
import { useDispatch } from '../../../store'
import { AddNewUsersState } from '../../modules/AddUserForm/reducer'
import { AddNewUserModal } from '../AddNewUserModal/AddNewUserModal'
import {
  LabraToolTipV2,
  ButtonV2,
  PopoverHover,
} from '@labrav/react-components'
import { useLocation, useNavigate } from 'react-router-dom'
import { usePartner } from '../../utils/Hooks/hooks'
import { usePartnerId, useUserType } from '../../utils/Hooks/usePartnerData'
import { usePartnerUser } from '../../utils/Hooks/partner'
import { ssoInviteDisableMessage } from '../../utils/messagesContants'

export const AvatarName = ({
  isAdmin,
  user,
  index,
}: {
  isAdmin: boolean
  user: UserState
  index: number
}) => {
  const classes = useStyles()
  const styles: string[] = [classes.user1, classes.user2, classes.user3]
  return (
    <LabraToolTipV2 tooltipPlacement="bottom" description={user?.name}>
      <Avatar
        className={clsx(
          classes.avatar,
          isAdmin ? classes.admin : styles[index]
        )}
        data-testid={`${isAdmin ? 'admin' : 'staff'}-members-name`}
      >
        {get(user, 'name[0]', '').toUpperCase()}
      </Avatar>
    </LabraToolTipV2>
  )
}

export const AvatarsForName: React.FC<{
  users: UserState[]
  isAdmin: boolean
}> = ({ users, isAdmin }) => {
  return (
    <>
      {users.map((user, index) => (
        <AvatarName isAdmin={isAdmin} user={user} index={index} />
      ))}
    </>
  )
}
export const AvatarsForNumber: React.FC<{
  userName: string
  totalUsers: number
  isAdmin: boolean
}> = ({ userName, totalUsers, isAdmin }) => {
  const classes = useStyles()
  return (
    <LabraToolTipV2 tooltipPlacement="bottom" description={userName}>
      <Avatar
        className={clsx(classes.avatar, classes.avatarNumber)}
        data-testid={`${isAdmin ? 'admin' : 'staff'}-members-number`}
      >{`+${totalUsers}`}</Avatar>
    </LabraToolTipV2>
  )
}
export const TeamMembers: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const partnerType = useUserType()
  const queryParams = new URLSearchParams(location.search)
  const firstTimeValue = queryParams.get('newFirst') === 'true'
  const [open, setOpen] = useState<boolean>(firstTimeValue || false)
  const [firstTime, setFirstTime] = useState<boolean>(firstTimeValue || false)
  const users = useSelector<RootState, UserState[]>(
    state => state.userList[partnerType].userList.users || []
  )
  const [openDisabledMessage, setOpenDisabledMessage] = useState<boolean>(false)
  const { openForm, errors, loading } = useSelector<
    RootState,
    AddNewUsersState
  >(state => state.addNewUsers.metadata)
  const handleOpen = () => {
    dispatch(showAddUserForm())
  }
  const partnerId = usePartnerId()
  const partnerUser = useUserType()
  const { partnerData } = usePartner(partnerId || '', partnerUser)
  const shouldInviteButtonDisabled = partnerData?.authProtocol === 'EXTERNAL'
  const removeQueryParam = () => {
    queryParams.delete('newFirst')
    navigate(
      {
        search: queryParams.toString(),
      },
      { replace: true }
    )
  }

  const handleAction = async () => {
    await setOpen(false)
    await setFirstTime(false)
    if (queryParams?.has('newFirst')) {
      removeQueryParam()
    }

    setTimeout(async () => {
      await handleOpen()
    }, 300)
  }

  const inviteLater = async () => {
    if (queryParams?.has('newFirst')) {
      removeQueryParam()
    }
    await setOpen(false)
    await setFirstTime(false)
  }

  const adminUsers = users.filter(user => isAdminOrOwner(user))
  const firstAdminUser = adminUsers.slice(0, 1)
  const remainingAdminUsers = adminUsers.slice(1, adminUsers.length + 1)
  const namesOfRemainingAdminUsers = remainingAdminUsers
    .map(user => get(user, 'name', ''))
    .join(', ')
  const staffUsers = users.filter(user => !isAdminOrOwner(user))
  const firstThreeStaffUsers = staffUsers.slice(0, 3)
  const remainingStaffUsers = staffUsers.slice(3, staffUsers.length + 1)
  const namesOfRemainingStaffUsers = remainingStaffUsers
    .map(user => get(user, 'name', ''))
    .join(', ')

  const teamMememberTooltipDesc = (
    <>
      <ol className={classes.orderList} data-testid={'tooltip-description'}>
        <li>Filling your company details like company name, origin etc</li>
        <li>
          Connecting your Cloud with Labra by running CFT and creating IAM
          roles. (Your AWS Admin will be able to help with this)
        </li>
        <li>
          Submitting all Listing information which includes your product,
          pricing, buyer registration page and notification details for your
          listing.
        </li>
      </ol>
    </>
  )
  return (
    <div data-testid="team-members" className={classes.teamMembersContainer}>
      <div className={classes.teamMembersHeadingContainer}>
        <Typography className={classes.teamMembersHeading}>
          Team members
        </Typography>
        <PopoverHover
          tooltipPlacement="right-start"
          label={'Invite members from your team who can assist you with:'}
          description={teamMememberTooltipDesc}
          open={open}
          setOpen={setOpen}
          shouldAutoClose={shouldInviteButtonDisabled}
          firstTime={firstTime}
          actionName={shouldInviteButtonDisabled ? undefined : 'Invite now'}
          secondaryActionName={
            shouldInviteButtonDisabled ? undefined : 'Invite later'
          }
          handleAction={shouldInviteButtonDisabled ? undefined : handleAction}
          handleSecondaryAction={inviteLater}
        >
          <div className={classes.infoIconContainer}>
            <FontAwesomeIcon
              icon={faInfoCircle}
              style={{ height: 16, width: 16, display: 'flex' }}
              className={classes.infoIcon}
            />
          </div>
        </PopoverHover>
      </div>

      <div className={classes.teamMembersTypeContainer}>
        <div
          className={classes.teamMembersTypeContainerItem}
          style={{ width: 61 }}
        >
          <Typography className={classes.teamMembersType}>Admin</Typography>
          <div className={classes.avatarGroup}>
            <AvatarsForName users={firstAdminUser} isAdmin={true} />
            {remainingAdminUsers.length > 0 ? (
              <AvatarsForNumber
                userName={namesOfRemainingAdminUsers}
                isAdmin={true}
                totalUsers={remainingAdminUsers.length}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.teamMembersTypeContainerItem}>
          <Typography className={classes.teamMembersType}>Staff</Typography>
          <div className={classes.avatarGroup}>
            <AvatarsForName users={firstThreeStaffUsers} isAdmin={false} />
            {remainingStaffUsers.length > 0 ? (
              <AvatarsForNumber
                userName={namesOfRemainingStaffUsers}
                isAdmin={false}
                totalUsers={remainingStaffUsers.length}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {shouldInviteButtonDisabled ? (
        <PopoverHover
          tooltipPlacement="right-start"
          label={''}
          description={ssoInviteDisableMessage}
          open={openDisabledMessage}
          setOpen={setOpenDisabledMessage}
        >
          <InviteTeamMembersButton isDisabled={true} handleOpen={handleOpen} />
        </PopoverHover>
      ) : (
        <InviteTeamMembersButton isDisabled={false} handleOpen={handleOpen} />
      )}

      <AddNewUserModal openForm={openForm} errors={errors} />
    </div>
  )
}

export interface InviteTeamMembersButtonProps {
  isDisabled: boolean
  handleOpen: () => void
}
export const InviteTeamMembersButton = React.memo(
  ({ isDisabled, handleOpen }: InviteTeamMembersButtonProps) => {
    return (
      <ButtonV2
        data-testId="add-new-team-members-button"
        styleType={'link'}
        smallVariant
        onClick={handleOpen}
        disabled={isDisabled}
      >
        Invite team members
      </ButtonV2>
    )
  }
)
