import { AnyAction, Dispatch } from 'redux'
import { getErrorMessages } from '../../../common/utils/error'
import { RequestFailureMessage } from '../../../common/utils/messagesContants'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { errorLogger } from '../../../common/utils/errorLogger'
import { AppDispatch, RootState } from '../../../store'
import { UsageDataState, UsageFilterState } from './reducer'
import { camelize } from 'casing'
import FileDownload from 'js-file-download'
import { fetchMeteringReport, fetchUsageData } from '../../api/markeplace'
import { DateRange } from '../revenueInsightsV2/reducer'

export enum UsageMeteringActionTypes {
  SET_USAGE_METERING_DATA = 'SET_USAGE_METERING_DATA',
  CLEAR_USAGE_METERING_DATA = 'CLEAR_USAGE_METERING_DATA',
  SET_USAGE_DATE_RANGE = 'SET_USAGE_DATE_RANGE',
  SET_USAGE_FILTERS = 'SET_USAGE_FILTERS',
  CLEAR_USAGE_FILTERS = 'CLEAR_USAGE_FILTERS',
  SET_PAGE_NUMBER = 'SET_PAGE_NUMBER',
}

export const getUsageData =
  ({
    pageSize,
    pageNumber,
    cloud,
    status,
  }: {
    pageSize?: number
    pageNumber?: number
    cloud?: string[]
    status?: string[]
  }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.USAGE_LOADING))
    try {
      const partnerId = getState().PartnerData.user.partnerData?.partnerId || ''
      const { dateRangeFilter } = getState().usageData.filters
      const finalStartDate = dateRangeFilter.startDate
      const finalEndDate = dateRangeFilter.endDate

      const { data } = await fetchUsageData({
        partnerId,
        pageNumber,
        pageSize,
        cloud,
        status,
        finalStartDate,
        finalEndDate,
      })
      await dispatch(setUsageMeteringData(camelize(data)))
      await dispatch(
        updateAppAlert({
          message: '',
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(error?.response),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.USAGE_LOADING))
    }
  }
export const setUsageMeteringData = (usageData: UsageDataState) => {
  return {
    type: UsageMeteringActionTypes.SET_USAGE_METERING_DATA,
    payload: usageData,
  }
}
export const clearUsageMeteringData = () =>
  ({
    type: UsageMeteringActionTypes.CLEAR_USAGE_METERING_DATA,
  } as unknown as AnyAction)

export const getMeteringReportFile =
  (
    fromDate: string,
    toDate: string,
    cloudProductId: string,
    cloudProvider: string
  ) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.MARKETPLACE_INFO))

    try {
      const partnerId = getState().PartnerData.user.partnerData?.partnerId || ''
      const response = await fetchMeteringReport({
        partnerId,
        fromDate,
        toDate,
        cloudProductId,
      })

      const filename = `metering_history_${cloudProvider}_${cloudProductId}_${fromDate}_${toDate}.csv`
      FileDownload(response.data, filename)

      dispatch(
        updateAppAlert({
          message: 'Metering history downloaded.',
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(error?.response),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
      throw error
    } finally {
      await dispatch(stopLoading(LoadingTypes.MARKETPLACE_INFO))
    }
  }
export const updateUsageDateRange =
  (dates: DateRange) => async (dispatch: AppDispatch) => {
    const { startDate, endDate } = dates
    await dispatch(setUsageDateRange({ startDate, endDate }))
  }

export const setUsageDateRange = (dates: DateRange) => ({
  type: UsageMeteringActionTypes.SET_USAGE_DATE_RANGE,
  payload: dates,
})
export const setFilters = (filters: UsageFilterState) => ({
  type: UsageMeteringActionTypes.SET_USAGE_FILTERS,
  payload: filters,
})
export const clearFilters = () => ({
  type: UsageMeteringActionTypes.CLEAR_USAGE_FILTERS,
})
export const setUsagePageNumber = (pageNumber: number) => ({
  type: UsageMeteringActionTypes.SET_PAGE_NUMBER,
  payload: pageNumber,
})
