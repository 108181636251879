import React, { useCallback, useEffect } from 'react'
import { useStyles } from './AddNewUserModal.styles'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import { Role, UserRole } from '../../utils/roles'
import IconButton from '@material-ui/core/IconButton'
import { validate as isValidEmail } from 'email-validator'
import { FormControl, Typography } from '@material-ui/core'
import {
  addRowForAddUser,
  addUserErrorData,
  hideAddUserForm,
  removeRowForAddUser,
  submitAddNewUsersData,
  updateRowForAddUser,
} from '../../modules/AddUserForm/actions'
import { useDispatch, useSelector } from '../../../store'
import {
  ErrorUsers,
  HandleType,
  UserType,
} from '../../modules/AddUserForm/reducer'
import { ArrayReducerRow } from '../../modules/arrayReducer'
import { extractData } from '../../modules/arrayReducer/utils'
import {
  DataType,
  FormField,
} from '@labrav/react-components/lib/@types/types/forms'
import {
  ButtonV2,
  LabraToolTipV2,
  ModalConfirmV2,
  ModalSizeVariants,
  NoteV2,
  SelectV2,
  TextInputV2,
} from '@labrav/react-components'
import { useFlagValue } from '@labrav/flags'
import {
  AuthProvider,
  getUsers,
} from '../../../oppsync/modules/userList/action'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/pro-regular-svg-icons'
import { HelpDrawer } from '../HelpDrawerV2/HelpDrawer'
import { UserState } from '../../../oppsync/modules/userList/reducer'
import { InvitedUsers } from './InvitedUsers/InvitedUsers'
import { isEmpty } from 'lodash'
import clsx from 'clsx'
import { UserProfileState } from '../../modules/userProfile/reducer'
import { useUserType } from '../../utils/Hooks/usePartnerData'

export const AddNewUserModal: React.FC<{
  openForm: boolean
  errors: ErrorUsers[]
}> = ({ openForm, errors }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const partnerType = useUserType()
  const { userProfile } = useSelector<UserProfileState>(
    state => state.userProfile
  )
  const isStaffUser =
    userProfile?.roles && userProfile?.roles[0] === Role.PARTNER_STAFF

  const formDisabled = !!isStaffUser

  const formDisabledToolTipMessage =
    'Your role does not permit you to invite members.'

  const users = useSelector<ArrayReducerRow<UserType>[]>(
    state => state.addNewUsers.users
  )
  const invitedUsers = useSelector<UserState[]>(
    state => state.userList[partnerType].userList.users
  )

  const partnerId = useSelector<string | undefined>(
    state => state.PartnerData.user?.partnerData?.partnerId
  )

  const { loading: isFlagsLoading, value: auth0Enabled } = useFlagValue('auth0')
  const authProvider = auth0Enabled ? AuthProvider.AUTH0 : AuthProvider.OKTA

  useEffect(() => {
    if (partnerId && !isFlagsLoading) {
      dispatch(
        getUsers({
          partnerId: partnerId || '',
          authProvider,
          pageNumber: 1,
          pageSize: 500,
        })
      )
    }
  }, [partnerId, isFlagsLoading])

  const removeAllOtherRowsExceptFirst = async () => {
    await dispatch(addUserErrorData([]))
    const remainingUserIds = users.map(user => user.id)
    remainingUserIds.forEach(userId => {
      dispatch(removeRowForAddUser(userId))
    })
  }
  const handleClose = () => {
    removeAllOtherRowsExceptFirst()
    dispatch(hideAddUserForm())
  }

  const validateForm = useCallback(
    (newUsers: ArrayReducerRow<UserType>[]) => {
      const newErrorArray: ErrorUsers[] = []

      newUsers.forEach(({ data }, index) => {
        const errObject = [...errors].find(errItem => errItem.index === index)
        newErrorArray.push({
          index: index,
          touched: errObject?.touched || false,
          error: getEmailValidationMessage(data.email),
        })
      })

      dispatch(addUserErrorData(newErrorArray))
    },
    [errors]
  )
  // handle input change
  const handleInputChange = async (event: HandleType, idChanging: string) => {
    const updatedUser = users.find(({ id }) => id === idChanging)
    if (!updatedUser) {
      console.error(
        'updating user with id: ',
        idChanging,
        ' not possible because id was not found'
      )
      return
    }
    await dispatch(
      updateRowForAddUser(idChanging, {
        ...updatedUser.data,
        [event.name]: event.value,
      })
    )
  }

  const getEmailValidationMessage = (value: string) => {
    if (isEmpty(value)) {
      return 'Please provide email address.'
    }

    return !isValidEmail(value) ? 'Please provide valid email address.' : ''
  }

  const handleBlurInput = async (event: HandleType, idChanging: string) => {
    const updatedUserIndex = users.findIndex(({ id }) => id === idChanging)
    const updatedErr = [...errors]
    const errobjectIndex = updatedErr?.findIndex(
      err => err.index === updatedUserIndex
    )
    if (updatedErr[errobjectIndex] && !updatedErr[errobjectIndex]?.touched) {
      updatedErr[errobjectIndex] = {
        touched: true,
        error: getEmailValidationMessage(event.value),
        index: updatedUserIndex,
      }
      await dispatch(addUserErrorData(updatedErr))
    }
  }

  // handle click event of the Remove button
  const handleRemoveClick = async (id: string) => {
    await dispatch(removeRowForAddUser(id))
  }

  // handle click event of the Add button
  const handleAddClick = () => {
    dispatch(addRowForAddUser())
  }
  const userRoleOptions = Object.entries(UserRole).map(([key, value]) => ({
    label: value,
    value: key,
  }))

  const isDisable =
    errors.filter(errorItem => !isEmpty(errorItem.error)).length > 0

  const onSubmit = async () => {
    if (partnerId) {
      await dispatch(
        submitAddNewUsersData(
          extractData(users),
          partnerId,
          auth0Enabled ? AuthProvider.AUTH0 : AuthProvider.OKTA,
          false,
          undefined,
          false
        )
      )
      removeAllOtherRowsExceptFirst()
    }
  }

  useEffect(() => {
    validateForm(users)
  }, [users])
  const noOps = () => void 0

  const addNewMemberFields: FormField = {
    title: 'Select a role',
    placeHolder: 'Select a role',
    id: 'role',
    isRequired: false,
    humanReadableKey: 'selectRole',
    isReadOnly: formDisabled || false,
    isCustom: false,
    defaultValue: 'PARTNER_STAFF',
    dataType: 'enum' as DataType,
    options: userRoleOptions,
  }

  return (
    <>
      <ModalConfirmV2
        title={'Manage team members'}
        description={
          <div>
            <Typography
              data-testid="team-member-decription"
              className={classes.description}
            >
              Invite your team members to come help you with the information
              required to onboard onto Labra.
            </Typography>
            <div
              data-testid="team-member-note-component"
              className={classes.note}
            >
              <NoteV2 description="The invitee will only get access to your Labra account by accepting the invite sent by Labra on their email." />
            </div>
          </div>
        }
        content={
          <AddUserRow
            users={users}
            handleInputChange={handleInputChange}
            errors={errors}
            addNewMemberFields={addNewMemberFields}
            handleRemoveClick={handleRemoveClick}
            handleAddClick={handleAddClick}
            handleBlurInput={handleBlurInput}
            isDisable={isDisable}
            formDisabled={formDisabled}
            formDisabledToolTipMessage={formDisabledToolTipMessage}
          />
        }
        hideCancelButton={true}
        buttonsPositionLeft={true}
        open={openForm}
        titleAcceptButton={'Invite'}
        onAccept={onSubmit}
        onDecline={handleClose}
        variant={ModalSizeVariants.large}
        icon={<PersonAddAltIcon />}
        secondaryActions={
          <HelpDrawer sectionName="inviteMembersHelp" headerTitle="Help" />
        }
        disabledActionButtonMessage={
          formDisabled ? formDisabledToolTipMessage : ''
        }
        acceptDisabled={formDisabled || isDisable}
        bottomContent={<InvitedUsers invitedUsers={invitedUsers} />}
      />
    </>
  )
}

interface AddUserRowProps {
  users: ArrayReducerRow<UserType>[]
  handleInputChange: (event: HandleType, idChanging: string) => void
  errors: ErrorUsers[]
  addNewMemberFields: FormField
  handleRemoveClick: (id: string) => void
  handleAddClick: () => void
  handleBlurInput: (event: HandleType, idChanging: string) => void
  isDisable: boolean
  formDisabled?: boolean
  formDisabledToolTipMessage?: string
}

export const AddUserRow = ({
  users,
  handleInputChange,
  errors,
  addNewMemberFields,
  handleRemoveClick,
  handleAddClick,
  handleBlurInput,
  isDisable,
  formDisabled,
  formDisabledToolTipMessage,
}: AddUserRowProps) => {
  const classes = useStyles()

  const handleChange =
    (id: string) =>
    (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      handleInputChange(
        { name: e.target.name as string, value: e.target.value as string },
        id
      )
    }

  const handleBlur =
    (id: string) =>
    (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      handleBlurInput(
        { name: e.target.name as string, value: e.target.value as string },
        id
      )
    }

  return (
    <div>
      <form autoComplete="off" data-testid="add-new-user-form">
        {users.map(({ id, data: user }, i) => {
          const isErrr = errors.find(
            ({ index, touched }) => index === i && touched
          )?.error
            ? true
            : false
          return (
            <div key={id} data-testid="add-user-row" className="box">
              <div className={classes.formContainer}>
                <div className={classes.fieldWidth}>
                  <FormControl className={classes.formControl}>
                    <LabraToolTipV2
                      tooltipPlacement="bottom-start"
                      description={
                        formDisabled ? formDisabledToolTipMessage : ''
                      }
                      tooltipPlacmentGap="32px"
                    >
                      <TextInputV2
                        keyName="email"
                        title="Email address"
                        value={user.email}
                        placeHolder=""
                        onChange={handleChange(id)}
                        isReadOnly={formDisabled || false}
                        isRequired={false}
                        onBlur={handleBlur(id)}
                        isError={isErrr}
                        errorText={
                          errors.find(
                            ({ index, touched }) => index === i && touched
                          )?.error || ''
                        }
                        type="email"
                        showSpinner={false}
                      />
                    </LabraToolTipV2>
                  </FormControl>
                </div>

                <div className={classes.fieldWidth}>
                  <LabraToolTipV2
                    tooltipPlacement="bottom-start"
                    description={formDisabled ? formDisabledToolTipMessage : ''}
                    tooltipPlacmentGap="32px"
                  >
                    <SelectV2
                      field={{ ...addNewMemberFields }}
                      onChange={handleChange(id)}
                      showNone={false}
                    />
                  </LabraToolTipV2>
                </div>
                <div
                  className={clsx(
                    classes.iconContainer,
                    isErrr ? classes.iconContainerErr : ''
                  )}
                >
                  {users.length !== 1 && (
                    <>
                      <IconButton
                        className={classes.deleteIcon}
                        onClick={() => handleRemoveClick(id)}
                        data-testid="remove-btn"
                        disabled={formDisabled}
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          width={'18px'}
                          height={'18px'}
                        />
                      </IconButton>
                    </>
                  )}
                </div>
              </div>

              {users.length - 1 === i && (
                <LabraToolTipV2
                  tooltipPlacement="bottom-start"
                  description={formDisabled ? formDisabledToolTipMessage : ''}
                  tooltipPlacmentGap="32px"
                >
                  <ButtonV2
                    styleType={'link'}
                    smallVariant
                    onClick={handleAddClick}
                    disabled={formDisabled || isDisable}
                    data-testid="add-one-more-user-btn"
                  >
                    Add a team member
                  </ButtonV2>
                </LabraToolTipV2>
              )}
            </div>
          )
        })}
      </form>
    </div>
  )
}
